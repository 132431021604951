<template>
  <div>
    <router-view name="NotificationBar"></router-view>
    <!-- 橫向提示 -->
    <!-- <div class="landscape flexH width height middle">
      <div class="icon white">
       <font-awesome-icon icon="fa-solid fa-rotate-right"  size="6x" />
      </div>
      <div class="flexV">
        <span class="fXXL white">請直向使用</span>
        <span class="fL secondary">目前只支援直向使用<br>
          請把手機旋轉至直立方向</span>
        </div>
    </div> -->
    <router-view name="Header"></router-view>
    <keep-alive :include="['Home']">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppLayout',
  data() {
    return {
      notificationPopupEnabled: false,
    };
  },
  computed: {
    ...mapState(['user', 'userInfo']),
  },
  mounted() {
    console.log(
      'mounted: ',
      process.env.VUE_APP_HOST,
      `${process.env.BASE_URL}`,
      process.env.VUE_APP_ENV
    );
    console.log('lang: ', localStorage.getItem('lang'));

    //NOTE: Howard: 在這裡建立通知message listener
    this.initNotificationListener();
    setTimeout(() => {
      this.notificationPopupEnabled = true;
    }, 3000);
  },
  methods: {
    //NOTE: Howard: 通知message listener。不分種類。
    //FIXME: 可能之後會需要依通知種類切換popup style
    initNotificationListener() {
      if (this.user && this.user.user) {
        console.log(
          'initNotificationListener: topic for this user is',
          this.user.user.firestoreChannelId
        );
        this.$store
          .dispatch('noti/init')
          .then(() => {
            console.log(
              `initNotificationListener: noti.init dispatched and resolved`
            );
          })
          .catch(err => {
            console.error(`initNotificationListener: error`, err);
          });
      }
    },
  },
};
</script>

<style src="@/assets/css/qcard.css"></style>
